import axios, { AxiosError } from "axios";
import url from "./url";

export default async function uploadPhoto(img: File, alt: string) {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.post(`${url}/api/main/user-photos/`, {
			session,
			photo: img,
			title: alt,
		}, {
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "multipart/form-data",
			}
		});
		return data?.photo as string;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		}
	}
}
