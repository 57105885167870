/* eslint-disable @next/next/no-img-element */
import axios from 'axios';
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import getAvailableSubscriptions, { Subscription as AvailableSubscription } from './api/get-available-subscriptions';
import url from './api/url';
import Arrow from './components/Arrow'
import num2str from './utils/num2str';
import './styles/style.scss'
import LK from './pages/lk';
import Editor from './pages/editor';
import Login from './pages/login';
import Register from './pages/register';
import ResetPassword from './pages/reset-password';
import ChangePassword from './pages/change-password';
import getSubscriptions, { Subscription } from './api/get-subscriptions';
import getUserInfo from './api/get-user-info';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function Home() {
	const [subscriptions, setSubscriptions] = useState<AvailableSubscription[]>([]);
	const [activeSubscription, setActiveSubscription] = useState<Subscription>();
	useEffect(() => {
		const { href } = window.location;
		const search = href.slice(href.indexOf('?') + 1);
		const params = new URLSearchParams(search);
		const payment_id = params.get('payment_id');
		if (payment_id) {
			axios.get(url + `/api/payments/call-back/${payment_id}`)
				.then(({ data }) => console.log(data))
				.catch(console.log)
		}

		(async () => {
			// General subscriptions list
			setSubscriptions(await getAvailableSubscriptions());
			// User's subscriptions
			const subscriptions = await getSubscriptions();
			const activeSubscription = subscriptions.find(s => s.activated);
			if (activeSubscription) {
				setActiveSubscription(activeSubscription);
			}
		})();
	}, []);

	const location = useLocation();
	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.slice(1))
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
		else {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		}
	}, [location]);

	const redirect = useNavigate();

	return (
		<>
			<main>
				<section className="section1">
					<div className="container">
						<h1 className="title">Создавай презентацию по любой квартире</h1>
						<p className="subtitle">
							с Propertyfinder в 1 клик
						</p>
						<Link to="#subscriptions">
							<button>Попробовать бесплатно</button>
						</Link>
						<div className="section1-examples">
							<img src="/examples/1.jpg" alt="" />
							<img src="/examples/2.jpg" alt="" />
							<img src="/examples/3.jpg" alt="" />
							<img src="/examples/hand.png" alt="" />
						</div>
					</div>
				</section>
				<section className="section2">
					<div className="container">
						<h2 className="section-title">
							<Arrow />
							Как это работает
						</h2>

						<ul className="section2-steps">
							<li>
								<h3>Шаг 1</h3>
								<p>Скопируй ссылку на <b>необходимый объект</b> и вставь ее в редактор</p>
							</li>
							<li>
								<h3>Шаг 2</h3>
								<p><b>Отредактируй</b> необходимую информацию, удали лишние фото или добавь свои фото</p>
							</li>
							<li>
								<h3>Шаг 3</h3>
								<p>Скачай готовую презентацию<b> в PDF или поделись ею</b> в whatsapp/telegram/почту</p>
							</li>
						</ul>
					</div>
				</section>
				<section className="section3">
					<div className="container">
						<h2 className="section-title">
							<Arrow />
							Преимущества работы
						</h2>
						<ul className="section3-list">
							<li>
								<div className="section3-list-img">
									<img src="/svg/speed.svg" alt="" style={{ marginTop: '-.9rem' }} />
								</div>
								<h3>35 секунд</h3>
								<p>
									Время на создание<br />
									презентации <b>35 секунд</b>
								</p>
							</li>
							<li>
								<div className="section3-list-img">
									<img src="/svg/export.svg" alt="" style={{ marginTop: '-.2rem' }} />
								</div>
								<h3>PDF</h3>
								<p>
									Делись презентацией<br />
									<b>в whats app, telegram, pdf</b>
								</p>
							</li>
							<li>
								<div className="section3-list-img">
									<img src="/svg/edit.svg" alt="" style={{ marginTop: '-.3rem' }} />
								</div>
								<h3>Smart<br/> шаблон</h3>
								<p>
									<b>Удаляй лишние слайды</b> и меняй<br />
									их местами
								</p>
							</li>
							<li>
								<div className="section3-list-img">
									<img src="/svg/plus.svg" alt="" />
								</div>
								<h3>Добавляй<br/> контент</h3>
								<p>
									Добавляй <b>свои фото</b>, текст<br/>
									и контакты
								</p>
							</li>
							<li>
								<div className="section3-list-img">
									<img src="/svg/rewind.svg" alt="" />
								</div>
								<h3>История<br/> создания</h3>
								<p>
									Храни сформированные<br />
									презентации и редактируй<br />
									<b>в любой момент</b>
								</p>
							</li>
							<li>
								<div className="section3-list-img">
									<img src="/svg/support.svg" alt="" />
								</div>
								<h3>На связи<br /> 24/7</h3>
								<p>
									<b>Online-поддержка 24/7</b> на<br />
									английском и русском языке
								</p>
							</li>
						</ul>
					</div>
				</section>
				<section className="section4">
					<div className="container">
						<div className="section4_content">
							<img
								className='video'
								src="/video-plate.png"
								alt=""
							/>

							<div className='section4_info'>
								<h2 className="section-title">
									<Arrow />
									Посмотрите как&nbsp;работает наш сервис
								</h2>

								<ul	>
									<li>
										<img src="/svg/edit.svg" alt="" style={{ marginTop: '-.5rem' }} />
										Редактируй текст
									</li>

									<li>
										<img src="/svg/images.svg" alt="" style={{ marginTop: '-.5rem' }} />
										Меняй местами слайды или&nbsp;удаляй&nbsp;их
									</li>

									<li>
										<img src="/svg/add.svg" alt="" style={{ marginTop: '-.5rem' }} />
										Добавляй свои фото
									</li>

									<li>
										<img src="/svg/download.svg" alt="" style={{ marginTop: '-.5rem' }} />
										Скачивай в удобном формате
									</li>
								</ul>

								<Link to="#subscriptions">
									<button>
										Попробовать сейчас
									</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section id="subscriptions" className="section5">
					<div className="container">
						<h2 className="section-title">
							<Arrow />
							Типы подписок
						</h2>
						<ul className="section5-plans">
							{subscriptions?.map((subscription, index) => {
								const isActive = subscription.id === activeSubscription?.subscription.id;
								const isFree = subscription.free || subscription.price === '0.00';
								const presentationsNumber = isFree
									? subscription.number_presentations_per_day
									: subscription.number_presentations;
								return (
									<li
										key={index}
										style={{
											'--color': ['#97BCF4', '#D7FD2E', '#2C2C2C'][index],
											'--btn-text-color': index === 1 ? '#404040' : null,
											outline: isActive ? '.75rem solid #D7FD2E' : null
										} as React.CSSProperties}
									>
										<div className="img">
											<img src={subscription.img} alt="" />
										</div>
										<div className="content">
											<h3>{subscription.title}</h3>
											<div className="price" style={{ color: '#989898' }}>
												${+(+subscription.price / 12).toFixed(1)}
												{!subscription.free &&
													<span>
														в месяц при<br />
														оплате за год
													</span>
												}
											</div>
											<ul>
												{!isFree &&
													<li>
														${+subscription.price_per_month}
														{' '}
														при оплате раз в&nbsp;месяц
													</li>
												}
												<li>
													<b>{presentationsNumber}</b>
													{' '}
													{num2str(presentationsNumber, ['презентация', 'презентации', 'презентаций'])}
													{' '}
													в&nbsp;{isFree ? 'день' : 'месяц'}
												</li>
											</ul>
											<button
												className="btn-year"
												disabled={isActive || +subscription.price < +activeSubscription?.subscription.price}
												onClick={async () => {
													const token = localStorage.getItem('token');
													const session = localStorage.getItem('session');
													// Проверка на авторизацию
													if (token && session) {
														const info = await getUserInfo();
														if (!info) {
															redirect("/login");
															return;
														}

														try {
															let token = localStorage.getItem('token')!;
															let session = localStorage.getItem('session')!;

															const { data } = await axios.post(`${url}/api/payments/create-payment-link/`, {
																session,
																subscription_id: subscription.id,
																period_type: 'month',
																period_value: 12,
																regular_payment: false,
																redirect_url: window.location.origin
															}, {
																headers: {
																	Authorization: `Token ${token}`
																}
															});
															if (data?.payment_url) {
																window.location.href = data.payment_url;
															}
														}
														catch (e) {
															console.log(e.response.data);
														}
													}
													else {
														redirect('/register');
														return;
													}
												}}
											>
												{isActive
													? 'Подключено'
													: isFree
														? 'Подключить'
														: 'Подключить на год'
												}
											</button>
											<button
												className={classNames("btn-month", (isActive || isFree) && 'hidden')}
												disabled={isActive || +subscription.price < +activeSubscription?.subscription.price}
												onClick={async () => {
													const token = localStorage.getItem('token');
													const session = localStorage.getItem('session');
													// Проверка на авторизацию
													if (token && session) {
														const info = await getUserInfo();
														if (!info) {
															redirect("/login");
															return;
														}

														try {
															const { data } = await axios.post(`${url}/api/payments/create-payment-link/`, {
																session,
																subscription_id: subscription.id,
																period_type: 'month',
																period_value: 1,
																regular_payment: false,
																redirect_url: window.location.origin
															}, {
																headers: {
																	Authorization: `Token ${token}`
																}
															});
															if (data?.payment_url) {
																window.location.href = data.payment_url;
															}
														}
														catch (e) {
															console.log(e.response.data);
														}
													}
													else {
														redirect('/register');
														return;
													}
												}}
											>
												Подключить на месяц
											</button>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</section>
			</main>
		</>
	)
}

createRoot(document.getElementById("root")!).render(
	<HashRouter>
		<Header />
		<Routes>
			<Route path="/"                element={<Home />} />
			<Route path="/lk"              element={<LK />} />
			<Route path="/editor"          element={<Editor />} />
			<Route path="/login"           element={<Login />} />
			<Route path="/register"        element={<Register />} />
			<Route path="/change-password" element={<ChangePassword />} />
			<Route path="/reset-password"  element={<ResetPassword />} />
		</Routes>
		<Footer />
	</HashRouter>
);
