import { AxiosError } from 'axios';
import { useState } from "react"
import Stack from "../../components/Stack/Stack";
import PasswordVisibilitySwitch from '../../components/PasswordVisibilitySwitch';
import changePassword from '../../api/change-password';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
	const [p1, setP1] = useState('');
	const [p1Hidden, setP1Hidden] = useState(true);
	const [p2, setP2] = useState('');
	const [p2Hidden, setP2Hidden] = useState(true);
	const [error, setError] = useState('');

	const redirect = useNavigate();

	return (
		<div className="flex-center">
			<div className="form">
				<h1>Смена пароля</h1>
				<form onSubmit={e => e.preventDefault()}>
					<Stack spacing="2rem">
						<div className="input-wrapper">
							<input
								type={p1Hidden ? "password" : "text"}
								placeholder="Новый пароль"
								value={p1}
								onChange={e => setP1(e.target.value)}
							/>
							<PasswordVisibilitySwitch onClick={e => setP1Hidden(!p1Hidden)} />
						</div>
						<div className="input-wrapper">
							<input
								type={p2Hidden ? "password" : "text"}
								placeholder="Новый пароль ещё раз"
								value={p2}
								onChange={e => setP2(e.target.value)}
							/>
							<PasswordVisibilitySwitch onClick={e => setP1Hidden(!p1Hidden)} />
						</div>
						<button
							type="submit"
							onClick={async e => {
								e.preventDefault();
								if (p1 !== p2) {
									setError('Пароли не совпадают!');
									return;
								}
								try {
									await changePassword(p1);
									redirect('/login');
								}
								catch (error) {
									if (error instanceof AxiosError) {
										let message = error.response?.data;
										if (typeof message === 'object') {
											message = message?.detail;
										}
										setError(message);
									}
								}
							}}
						>
							Сменить пароль
						</button>
						{error &&
							<p>{error}</p>
						}
					</Stack>
				</form>
			</div>
		</div>
	)
}
