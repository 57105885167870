import { AxiosError } from "axios";
import 'core-js/es/array/at';
import getSubscriptions from "./get-subscriptions";

export default async function getSubscriptionExpireDate() {
	try {
		const subscriptions = await getSubscriptions();
		if (subscriptions?.length) {
			const subscription = subscriptions.at(-1).expiration_date;
			const date = new Date(subscription);
			const { format } = Intl.DateTimeFormat('ru')
			return format(date);
		}
		return null;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		}
	}
}
