import axios, { AxiosError } from "axios";
import handleUnauthorized from "./handle-unauthorized";
import url from "./url";

export type Subscription = {
	id: number,
	product_id: string | number,
	archived: boolean,
	free: boolean,
	unlimited: boolean,
	title: string,
	img: string,
	price: string,
	price_per_month: string,
	price_per_week: string,
	number_presentations: number,
	number_presentations_per_day: number
};

export default async function getAvailableSubscriptions() {
	try {
		const { data: subscriptions } = await axios.get<Subscription[]>(`${url}/api/payments/subscriptions/`, {
		});
		return subscriptions.filter(sub => !sub.archived);
	}
	catch (error) {
		if (error instanceof AxiosError) {
			handleUnauthorized(error);
			console.error(error);
		}
	}
}
