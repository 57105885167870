import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import getSubscriptions, { Subscription } from '../../api/get-subscriptions';
import getUserInfo, { UserInfo } from '../../api/get-user-info';
import styles from './Header.module.scss';

export default function Header() {
	// TODO: Rename to 'user'
	const [userInfo, setUserInfo] = useState<UserInfo>();
	const [subscription, setSubscription] = useState<Subscription>();
	const location = useLocation();
	useEffect(() => {
		getSubscriptions()
			.then(subscriptions => {
				const activeSubscription = subscriptions.find(sub => sub.activated);
				if (activeSubscription) {
					setSubscription(activeSubscription);
				}
				else {
					setSubscription(null);
				}
			})
			.catch(error => setSubscription(null));

		(async () => {
			const token = localStorage.getItem('token');
			const session = localStorage.getItem('session');
			if (token && session) {
				try {
					const info = await getUserInfo();
					if (info) {
						setUserInfo(info);
					}
					else throw 'Unauthorized';
				}
				catch (error) {
					setUserInfo(null);
				}
			}
			else {
				setUserInfo(null);
			}
		})()
	}, [location]);

	return (
		<header className={styles.wrapper}>
			<div className="container">
				<div className={styles.inner}>
					<Link to="/">MISHKA.TECH</Link>

					<Link to={subscription ? '/lk#create-presentation' : '/#subscriptions'}>
						Создать презентацию
					</Link>

					<div className={styles.buttons}>
						{userInfo
							? <Link to="/lk">{userInfo.login}</Link>
							: <Link to="/login">Вход</Link>
						}
						<a href="#">Телефон</a>
					</div>
				</div>
			</div>
		</header>
	)
}
