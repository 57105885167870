/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import clx from 'classnames';
import Stack from "../../components/Stack/Stack";
import getUserInfo, { UserInfo } from '../../api/get-user-info';
import logout from '../../api/logout';
import getAvatar from '../../api/get-avatar';
import updateAvatar from '../../api/update-avatar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import getPDFHistory, { History } from '../../api/get-pdf-history';
import axios from 'axios';
import url from '../../api/url';
import getSubscriptions from '../../api/get-subscriptions';

export default function LK() {
	const [parseUrl, setParseUrl] = useState('');
	const [avatarSrc, setAvatarSrc] = useState('');
	const [user, setUser] = useState<UserInfo>({});
	const { firstname, lastname, email, subscription_end } = user;
	const [history, setHistory] = useState<History[]>([]);

	const location = useLocation();
	useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.slice(1))
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
		else {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		}
	}, [location]);

	const redirect = useNavigate();

	useEffect(() => {
		getUserInfo()
			.then(setUser)
			.catch(() => redirect('/login'));

		getAvatar()
			.then(setAvatarSrc)
			.catch(console.log);

		getPDFHistory()
			.then(setHistory)
			.catch(console.log);
	}, []);

	return (
		<>
		<div className="container">
			<section className="profile">
				<div className="profile-avatar">
					<img src={avatarSrc} alt="Фото пользователя" />
					<button
						className={clx(!avatarSrc && 'visible')}
							aria-label="Добавить фото профиля"
							title="Добавить фото профиля"
							onClick={e => {
								const inputElement = document.createElement("input");
								inputElement.type = "file";
								inputElement.accept = 'image/png, image/jpeg';
								inputElement.addEventListener("change", async function() {
									const file = this.files[this.files.length - 1];
									await updateAvatar(file);
									setAvatarSrc(await getAvatar());
									inputElement.remove();
								})
								inputElement.dispatchEvent(new MouseEvent("click"));
							}}>
						<img src="/svg/plus.svg" alt="" />
					</button>
				</div>
				<Stack spacing="4rem">
					<div className="profile-title-wrapper">
						<h2 className="profile-title">Профиль пользователя</h2>
						<button
							className="profile-logout-button button"
							onClick={async e => {
								try {
									await logout();
									redirect('/login');
								}
								catch (error) {
									console.error(error);
								}
							}}
						>
							Выйти
						</button>
					</div>

					<div>
						<b className="profile-name">{firstname ?? 'Имя'} {lastname ?? 'Фамилия'}</b>
						<span className="profile-email">{email ?? 'email@mail.com'}</span>
					</div>

					<div>
						<span className="profile-change-password-label">Пароль</span>
						<Link className="profile-change-password-button" to="/change-password">
							Сменить пароль
						</Link>
					</div>

					<span className="profile-expire-date">
						{subscription_end
							? `Дата окончания подписки ${subscription_end}`
							: 'Без подписки'
						}
					</span>

					<div className="profile-buttons">
						<button
							className="button button--filled"
							onClick={async () => {
								if (!subscription_end) {
									redirect('/#subscriptions', {
										preventScrollReset: true,
									});
									return;
								}

								try {
									const subscriptions = await getSubscriptions();
									const activeSubscription = subscriptions.find(s => s.activated)
									console.log(activeSubscription);

									let token = localStorage.getItem('token')!;
									let session = localStorage.getItem('session')!;
									const { data } = await axios.post(`${url}/api/payments/create-payment-link/`, {
										session,
										subscription_id: activeSubscription.subscription.id,
										number_months: 1,
										regular_payment: false,
										redirect_url: window.location.origin
									}, {
										headers: {
											Authorization: `Token ${token}`
										}
									});
									if (data?.payment_url) {
										window.location.href = data.payment_url;
									}
									console.log(data);
								}
								catch (e) {
									console.log(e.response.data);
								}
							}}
						>
							{subscription_end ? 'Продлить' : 'Купить'}
						</button>
						{subscription_end &&
							<button
								className="button"
								onClick={() => {
									redirect('/#subscriptions');
								}}
							>
								Улучшить
							</button>
						}
					</div>
				</Stack>
			</section>
		</div>
		<section id="create-presentation" className="create-presentation">
			<div className='create-presentation__title'>
				<svg width="97" height="92" viewBox="0 0 97 92" fill="none">
					<path fill="#D7FD2E" d="M25.7295 76.2524h70.8827v15.2884H25.7295zM10.23 0l61.727 55.5793-10.23 11.3615L0 11.3615z"/>
					<path fill="#D7FD2E" d="M96.6123 26.2175v51.4247H81.3239V26.2175z"/>
				</svg>
				<h2>Добавь ссылку, чтобы создать презентацию</h2>
			</div>

			<div className='create-presentation__input-container'>
				<input
					type="text"
					value={parseUrl}
					onChange={e => setParseUrl(e.target.value)}
				/>
				<button
					disabled={!parseUrl}
					onClick={() => {
						redirect(`/editor?url=${parseUrl}`);
					}}
				>
					Создать
				</button>
			</div>
		</section>
		<section className='history'>
			<div className='container'>
				<h3>История загрузок</h3>
				<ol>
					{history.map((h, index) =>
						<li key={index}>
							<a href={h.url} target="_blank">{h.name ?? h.url}</a>
							<div>
								<button
									className="download-btn"
									onClick={() => {
										const link = document.createElement("a");
										link.download = 'file.pdf';
										link.target = '_blank';
										link.href = h.url;
										document.body.appendChild(link);
										link.click();
										document.body.removeChild(link);
									}}
								>
									Скачать
								</button>

								<a className="share-btn" href={h.url} target="_blank">
									<svg width="41" height="33" viewBox="0 0 41 33" fill="none">
										<path d="M22.7731 4.4325V8.865l-1.7372.089c-8.0313.415-14.1116 4.4769-17.757 11.815C2.225 22.9037 1.5132 24.905.915 27.3659c-.4413 1.8382-1.0252 5.4999-.897 5.6333.0285.0296.5553-.7412 1.1819-1.7345 3.389-5.4109 6.351-8.4647 9.7827-10.1251 2.7198-1.3045 5.4823-1.8975 9.7542-2.105l2.0363-.089.0285 4.729.0427 4.7438 9.085-7.0712L41 14.2907l-9.1134-7.1453L22.7731 0v4.4325Z" fill="#fff"/>
									</svg>
								</a>
							</div>
						</li>
					)}
				</ol>
			</div>
		</section>
		</>
	);
}
