import axios, { AxiosError } from "axios";
import url from "./url";

export default async function register(
	username: string,
	password: string,
	phone:    string,
	email:    string,
	name:     string,
	surname:  string
) {
	console.log(
		username,
		password,
		phone,
		email,
	);

	try {
		const response = await axios.post(`${url}/api/main/registration`, {
			username,
			password,
			password_confirm: password,
			phone,
			email,
			first_name: name,
			last_name:  surname,
		});
		const data: {} = response.data;
		console.log(data);
		return data;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			const response = error.response.data;
			if (typeof response === 'object') {
				throw response;
				console.log(response);

				let message = response[Object.keys(response)[0]];
				if (Array.isArray(message)) {
					throw message[0];
				}
				throw message;
			}
		}
		else {
			throw error?.message ?? error;
		}
	}
}
