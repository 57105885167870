import { AxiosError } from "axios";
import { redirect } from "react-router-dom";

export default async function handleUnauthorized(error: AxiosError) {
	const data = error.response?.data as Record<string, unknown>;
	if (typeof data === 'object') {
		if (data?.detail === 'Invalid token.') {
			redirect('/login');
		}
	}
}
