import { useState } from 'react';

export default function PasswordVisibilitySwitch(props: {
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) {
	const { onClick } = props;
	const [visible, setVisible] = useState(false);
	return (
		<button
			className="eye-button"
			onClick={e => {
				setVisible(!visible)
				onClick?.(e);
			}}
			title={(visible ? 'Скрыть' : 'Показать') + ' пароль'}
		>
			{visible
				?
				<svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path stroke="#464455" stroke-linecap="round" stroke-linejoin="round" d="M4 12s1.6-5 8-5m0 0c6.4 0 8 5 8 5m-8-5V4m6 1-2 2.5M6 5l2 2.5m7 5.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>
				:
				<svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path stroke="#464455" stroke-linecap="round" stroke-linejoin="round" d="M4 10s1.6 5 8 5m0 0c6.4 0 8-5 8-5m-8 5v3m6-1-2-2.5M6 17l2-2.5" /></svg>
			}
		</button>
	);
}
