import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import getUserInfo, { UserInfo } from "../../api/get-user-info";

export default function Footer() {
	// TODO: Rename to 'user'
	const [userInfo, setUserInfo] = useState<UserInfo>();
	const location = useLocation();
	useEffect(() => {
		(async () => {
			const token = localStorage.getItem('token');
			const session = localStorage.getItem('session');
			if (token && session) {
				try {
					const info = await getUserInfo();
					if (info) {
						setUserInfo(info);
					}
					else throw 'Unauthorized';
				}
				catch (error) {
					setUserInfo(null);
				}
			}
			else {
				setUserInfo(null);
			}
		})()
	}, [location]);

	return (
		<footer className="footer">
			<div className="container">
				<div className='footer_content'>
					<div className='contacts'>
						<span>Следите за нами в социальных сетях</span>

						<div className='links'>
							<button>Написать нам</button>

							<ul>
								<li>
									<a
										href="https://wa.me/+79685355676?text=Добрый день с сайта mishka7.tech"
										target="_blank"
										aria-label="Написать нам в WhatsApp"
									>
										<img src="/svg/socials/whatsapp.svg" alt="WhatsApp" />
									</a>
								</li>
								<li>
									<a
										href="https://tgtg.su/natalii_nataliii"
										target="_blank"
										aria-label="Написать нам в Telegram"
									>
										<img src="/svg/socials/telegram.svg" alt="Telegram" />
									</a>
								</li>
								<li>
									<a
										href="https://instagram.com/mishka.tech?igshid=YmMyMTA2M2Y="
										target="_blank"
										aria-label="Мы в Instagram"
									>
										<img src="/svg/socials/instagram.svg" alt="Instagram" />
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className='navigation'>
						<nav>
							<ul>
								<li><Link to={{ pathname: '/', hash: 'subscriptions' }}>Тарифы</Link></li>
								{!userInfo &&
									<>
										<li><Link to="/register">Зарегистрироваться</Link></li>
										<li><Link to="/login">Вход</Link></li>
									</>
								}
								<li>Сообщить об ошибке</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</footer>
	);
}
