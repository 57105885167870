import { AxiosError } from 'axios';
import { useState } from "react"
import { Link } from 'react-router-dom';
import Stack from "../../components/Stack/Stack";
import resetPassword from '../../api/reset-password';

export default function ResetPassword() {
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	return (
		<div className="flex-center">
		<div className="form">
			<h1>Восстановление пароля</h1>
			<form onSubmit={e => e.preventDefault()}>
				<Stack spacing="2rem">
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<br />
					<br />
					<button
						type="submit"
						onClick={async e => {
							e.preventDefault();
							try {
								await resetPassword(email);
								setError('Пароль успешно сброшен! Проверьте свою почту.');
							}
							catch (error) {
								if (error instanceof AxiosError) {
									let message = error.response?.data;
									if (typeof message === 'object') {
										message = message?.detail;
									}
									setError(message);
								}
							}
						}}
					>
						Сбросить пароль
					</button>
					{error &&
						<p>{error}</p>
					}
				</Stack>
			</form>
		</div>
		</div>
	)
}
