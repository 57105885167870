import axios, { AxiosError } from 'axios';
import handleUnauthorized from './handle-unauthorized';
import url from './url';

export default async function getPage(pageURL: string): Promise<Record<string, string> | string> {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.post(`${url}/api/get_page/`, {
			session,
			link: pageURL
		}, {
			headers: {
				Authorization: `Token ${token}`
			}
		});
		if (data?.message === 'This page is no longer available.') {
			return data.message;
		}
		const result: Record<string, string> = data?.value;
		return result;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			handleUnauthorized(error);
			console.error(error);
		}
	}
}
