import axios, { AxiosError } from 'axios';
import handleUnauthorized from './handle-unauthorized';
import url from './url';

export type History = {
	id:         number,
	created_at: string,
	name:       string,
	url:        string
};

export default async function getPDFHistory(): Promise<History[]> {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.get<{
			id:        number,
			created:   string,
			file_name: string,
			pdf:       string
		}[]>(`${url}/api/presentations/history-pdfs/`, {
			data: {
				session
			},
			headers: {
				Authorization: `Token ${token}`
			}
		});
		return data.map(history => ({
			id:         history.id,
			name:       history.file_name,
			created_at: history.created,
			url:        history.pdf
		}));
	}
	catch (error) {
		if (error instanceof AxiosError) {
			handleUnauthorized(error);
			throw error.response.data;
		}
	}
}
