import axios, { AxiosError } from "axios";
import url from "./url";

export default async function changePassword(password: string) {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		await axios.post(`${url}/api/password-change/`, {
			session,
			password
		}, {
			headers: {
				Authorization: `Token ${token}`
			}
		});
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		}
	}
}
