import { AxiosError } from 'axios';
import { useState } from "react"
import { Link, useNavigate } from 'react-router-dom';
import login from "../../api/login";
import Stack from "../../components/Stack/Stack";
import PasswordVisibilitySwitch from '../../components/PasswordVisibilitySwitch';

export default function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordHidden, setPasswordHidden] = useState(true);
	const [error, setError] = useState('');

	const redirect = useNavigate();

	return (
		<div className="flex-center">
		<div className="form">
			<h1>Авторизация</h1>
			<form onSubmit={e => e.preventDefault()}>
				<Stack spacing="2rem">
					<input
						type="text"
						placeholder="Логин"
						autoComplete="username"
						value={username}
						onChange={e => setUsername(e.target.value)}
					/>
					<div className="input-wrapper">
						<input
							type={passwordHidden ? 'password' : 'text'}
							placeholder="Пароль"
							autoComplete="current-password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<PasswordVisibilitySwitch onClick={e => setPasswordHidden(!passwordHidden)} />
					</div>
					<Link to="/reset-password" style={{ alignSelf: 'flex-end' }}>Забыл пароль</Link>
					<div style={{ display: 'flex', alignItems: 'baseline', gap: '15px' }}>
						<button
							type="submit"
							onClick={async e => {
								e.preventDefault();
								try {
									const { token, session } = await login(username, password);
									localStorage.setItem('token', token);
									localStorage.setItem('session', session);
									// Переходим на страницу личного кабинета
									redirect('/lk');
								}
								catch (error) {
									if (error instanceof AxiosError) {
										let message = error.response?.data;
										if (typeof message === 'object') {
											message = message?.detail;
										}
										setError(message);
									}
									else {
										setError(error);
									}
								}
							}}
						>
							Войти
						</button>
						{/* или */}
						{/* <Link to="/register">Зарегистрироваться</Link> */}
					</div>
					{error &&
						<p>{error}</p>
					}
				</Stack>
			</form>
		</div>
		</div>
	)
}
