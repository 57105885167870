import axios, { AxiosError } from "axios";
import { redirect } from "react-router-dom";
import getSubscriptionExpireDate from "./get-subscription-expire-date";
import url from "./url";

export type UserInfo = Partial<{
	id: number;
	login: string;
	need_change_pass: boolean
	email: string;
	phone: string;
	firstname: string;
	lastname: string;
	is_staff: boolean;
	/** "2022-11-21T07:00:38Z" */
	date_joined: string;
	subscription_end: string;
}>;

export default async function getUserInfo() {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.post(`${url}/api/get_user_info/`, {
			session
		}, {
			headers: {
				Authorization: `Token ${token}`
			}
		});
		const info: UserInfo = data?.value;
		const subscription_end = await getSubscriptionExpireDate();
		return { ...info, subscription_end };
	}
	catch (error) {
		if (error instanceof AxiosError) {
			const data = error.response?.data;
			if (data?.detail === 'Invalid token.') {
				throw data.detail;
			}
		}
		throw error;
	}
}
