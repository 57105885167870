import axios, { AxiosError } from "axios";
import url from "./url";
import 'core-js/es/array/at';
import handleUnauthorized from "./handle-unauthorized";

export type Subscription = {
	activated: boolean;
	/** "2022-12-09T17:00:16.896695Z" */
	created: string;
	/** "2022-12-09T17:00:16.896695Z" */
	expiration_date: string;
	id: number,
	/** Expired or uer changed to another plan */
	off: boolean,
	subscription: {
		archived: boolean
		free: boolean;
		id: number;
		number_presentations: number;
		/** "0.00" */
		price: string;
		product_id: string;
		title: string;
		unlimited: boolean;
	};
	user: number;
};

export default async function getSubscriptions() {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.get<Subscription[]>(`${url}/api/payments/user-subscriptions/`, {
			data: {
				session
			},
			headers: {
				Authorization: `Token ${token}`
			}
		});
		return data.filter(sub => !sub.off);
	}
	catch (error) {
		if (error instanceof AxiosError) {
			handleUnauthorized(error);
			console.error(error.response?.data);
		}
	}
}
