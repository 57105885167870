import axios, { AxiosError } from 'axios';
import handleUnauthorized from './handle-unauthorized';
import url from './url';

export default async function getPDF(code: string, file_name: string, link: string, imgs: string[]) {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		const { data } = await axios.post<{
			message: string,
			value:   string
		}>(`${url}/api/get_pdf/`, {
			session,
			file_name,
			code,
			link,
			imgs
		}, {
			headers: {
				Authorization: `Token ${token}`
			}
		});
		console.log(data);
		return data.value;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			handleUnauthorized(error);
			const { data } = error.response;
			if (data?.message) {
				throw data.message;
			}
		}
	}
}
