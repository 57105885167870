import { HTMLAttributes } from "react";
import clx from 'classnames';
import styles from './Stack.module.scss';

type StackProps = HTMLAttributes<HTMLDivElement> & {
	spacing: string;
};

export default function Stack(props: StackProps) {
	return (
		<div
			{...props}
			className={clx(props.className, styles.stack, 'stack')}
			style={{ ...props.style, '--spacing': props.spacing } as React.CSSProperties}
		>
			{props.children}
		</div>
	);
}
