import axios, { AxiosError } from "axios";
import url from "./url";

export default async function updateAvatar(img: File, alt?: string) {
	let token = localStorage.getItem('token')!;
	let session = localStorage.getItem('session')!;

	try {
		await axios.post(`${url}/api/main/user-avatar/`, {
			session,
			title: alt ?? 'Test title',
			img
		}, {
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "multipart/form-data"
			}
		});
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		}
	}
}
