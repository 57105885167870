import axios, { AxiosError } from "axios";
import url from "./url";

export default async function getAvatar() {
	let token = localStorage.getItem('token')!;
	let session = localStorage.getItem('session')!;

	try {
		const { data } = await axios.get(`${url}/api/main/user-avatar/`, {
			data: {
				session,
			},
			headers: {
				Authorization: `Token ${token}`
			}
		});
		const src: string = data?.img;
		return src;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.error(error.response?.data);
		}
	}
}
