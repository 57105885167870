import axios, { AxiosError } from "axios";
import url from "./url";

export default async function resetPassword(email: string) {
	try {
		await axios.post(`${url}/api/password-recovery/`, {
			email
		});
	}
	catch (error) {
		if (error instanceof AxiosError) {
			throw error.response?.data;
		}
		throw error?.message ?? error;
	}
}
