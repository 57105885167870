import axios, { AxiosError } from "axios";
import handleUnauthorized from './handle-unauthorized';
import url from "./url";

export default async function logout() {
	const token = localStorage.getItem('token')!;
	const session = localStorage.getItem('session')!;
	try {
		await axios.post(`${url}/api/logout/`, {
			session
		}, {
			headers: {
				Authorization: `Token ${token}`
			}
		});
		localStorage.clear();
	}
	catch (error) {
		handleUnauthorized(error);
		if (error instanceof AxiosError) {
			let message = error.response?.data;
			console.error(message);
			throw message;
		}
		throw error?.message ?? error;
	}
}
