import { useState } from "react"
import Stack from "../../components/Stack/Stack";
import PasswordVisibilitySwitch from '../../components/PasswordVisibilitySwitch';
import login from "../../api/login";
import register from '../../api/register';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';

function formatPhone(phone: string) {
	const tel = phone.trim().replace(/\s+/g, '');
	if (tel.length === 11 && tel.startsWith('8')) {
		return tel.replace('8', '+7');
	}
	return tel;
}

export default function Register() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordHidden, setPasswordHidden] = useState(true);
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');

	const [error, setError] = useState<Record<string, string[]>>({});
	console.log(error, Object.keys(error));

	const redirect = useNavigate();

	return (
		<div className="flex-center">
		<div className="form">
			<h1>Регистрация</h1>
			<form onSubmit={e => e.preventDefault()}>
				<Stack spacing="2rem">
					<div
						className={classNames("input-wrapper", Object.keys(error).includes('email') && 'error')}
						data-error={Object.keys(error).includes('email') && error['email']?.[0]}
					>
						<input
							type="text"
							placeholder="Email"
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					<div
						className={classNames("input-wrapper", Object.keys(error).includes('password') && 'error')}
						data-error={Object.keys(error).includes('password') && error['password']?.[0]}
					>
						<input
							type={passwordHidden ? 'password' : 'text'}
							placeholder="Пароль"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<PasswordVisibilitySwitch onClick={e => setPasswordHidden(!passwordHidden)} />
					</div>
					<div
						className={classNames("input-wrapper", Object.keys(error).includes('phone') && 'error')}
						data-error={Object.keys(error).includes('phone') && error['phone']?.[0]}
					>
						<input
							type="tel"
							placeholder="Телефон"
							value={phone}
							onChange={e => setPhone(e.target.value)}
						/>
					</div>
					<input
						type="text"
						placeholder="Имя"
						value={name}
						onChange={e => setName(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Фамилия"
						value={surname}
						onChange={e => setSurname(e.target.value)}
					/>
					{error['non_field_errors'] &&
						<p className="error-text">{error['non_field_errors']?.[0]}</p>
					}
					<p>
						* указывайте корректные контактные данные,<br />
						они будут подставляться в ваши презентации
					</p>
					<div style={{ display: 'flex', alignItems: 'baseline', gap: '15px' }}>
						<button
							type="submit"
							onClick={async e => {
								e.preventDefault();
								try {
									await register(email, password, formatPhone(phone), email, name, surname);
									const { token, session } = await login(email, password);
									localStorage.setItem('token', token);
									localStorage.setItem('session', session);
									// Переходим на страницу личного кабинета
									redirect('/lk');
								}
								catch (error) {
									setError(error);
								}
							}}
						>
							Зарегистрироваться
						</button>
						{/* или */}
						{/* <Link to="/login">Войти</Link> */}
					</div>
				</Stack>
			</form>
		</div>
		</div>
	)
}
